import { useTranslation } from "react-i18next";
import { currency } from "../../../../../dynamicController";

const LaundroVending = ({ amount, setAmount, machine, minusAmount, addAmount, handleSubmit }) => {

    const { t } = useTranslation();

    return (
        <div>
            <h6 style={{ margin: "20px 0 5px 0" }}>
                <strong>{t("Select Payment Amount")}</strong>
            </h6>

            <div className="payment-amount">
                <span id="minus-amount" onClick={e => minusAmount()}>
                    <i className="bi bi-dash-circle-fill"></i>
                </span>

                <div>
                    <h6 className="m-0 p-0 font-weight-bold text-secondary">{t("AMOUNT TO PAY")}</h6>
                    {
                        machine.type === "Billacceptor" ? (
                            <div className="d-flex">
                                <h3 className="m-0 p-0 font-weight-bold">{currency(machine.country)}</h3>
                                <input type="number" id="amount" name="amount" className="ms-2 form-control browser-default text-center" value={amount} onChange={e => setAmount(e.target.value)} required min="0" max="100" step={machine.step || 1} />
                            </div>
                        ) : (
                            <h3 className="m-0 p-0 font-weight-bold">{currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}</h3>
                        )
                    }
                </div>

                <span id="add-amount" onClick={e => addAmount()}>
                    <i className="bi bi-plus-circle-fill"></i>
                </span>
            </div>

            <div className="list-group payment-list-group">
                <div className="list-group-item payment-list-group-item">
                    <div>
                        <p className="text-secondary">{t("PRICE")}</p>
                    </div>

                    <div>
                        <p>{currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}</p>
                    </div>
                </div>

                <div className="list-group-item payment-list-group-item">
                    <div>
                        <p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
                            {t("TOTAL")}
                        </p>
                    </div>

                    <div>
                        <p style={{ fontSize: "24px", fontWeight: "bolder" }}>{currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}</p>
                    </div>
                </div>
            </div>

            <div className="mt-5 px-3">
                <button id="pay" onClick={e => handleSubmit(e)} className="btn form-control big-button">
                    {t("PAY")}
                </button>
            </div>
        </div>
    )
}

export default LaundroVending;