import { useRef, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import jsQR from "jsqr";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

import { connect } from "react-redux";
import { scannedCode, saveCreds } from "../../../redux/actions/paymentActions";
import { checkToken } from "../../../redux/actions/userActions";
import { decrypt } from "../../../dynamicController";

import cleanproCameraIcon from "../../../assets/images/icons/icon_switchcamera.png";
import cleanproLightIcon from "../../../assets/images/icons/icon_flashlight.png";

const fullWidth = window.innerWidth;
const fullHeight = window.innerHeight;
const margin = (fullWidth * 10) / 100;

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Camera = ({ token, operatorId, user, scanned, saveCredentials, checkToken, theme }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const canvasRef = useRef();
	const video = useRef();
	const crop = useRef(false);
	const [constraint, setConstraint] = useState(null);
	const [videoDevices, setVideoDevices] = useState([]);
	const [torch, setTorch] = useState(false);
	const [flash, setFlash] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [switchCameraModal, setSwitchCameraModal] = useState(false);
	const [selectedDeviceId, setSelectedDeviceId] = useState("");
	// const [zoom, setZoom] = useState(0);

	const drawFrame = useCallback(() => {
		if (video && video.current && video.current.readyState === video.current.HAVE_ENOUGH_DATA) {
			const canvas = canvasRef.current;
			const ctx = canvas.getContext("2d");
			const img = video.current;
			const sx = img.videoWidth / 2 - fullWidth / 2;
			const sy = img.videoHeight / 2 - fullHeight / 2;
			const sw = fullWidth;
			const sh = fullHeight;
			// const sx = (img.videoWidth / 2 - fullWidth / 2) + zoom;
			// const sy = (img.videoHeight / 2 - fullHeight / 2) + zoom;
			// const sw = fullWidth - zoom;
			// const sh = fullHeight - zoom;
			const dx = 0;
			const dy = 0;
			const dw = fullWidth;
			const dh = fullHeight;
			//console.log({videoWidth:img.videoWidth, videoHeight: img.videoHeight, fullWidth, fullHeight })
			//{videoWidth: 640, videoHeight: 480, fullWidth: 414, fullHeight: 896}
			//console.log({sx,sy,sw,sh})
			//{sx: 113, sy: -208, sw: 414, sh: 896}

			// {videoWidth: 640, videoHeight: 480, fullWidth: 847, fullHeight: 738}
			//{sx: -103.5, sy: -129, sw: 847, sh: 738}

			ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);

			// const boxX = 0 + margin;
			const boxY = fullHeight / 2 - fullWidth / 2;
			const boxW = fullWidth - margin * 2;
			const boxH = boxW;

			// ctx.beginPath();
			// ctx.rect(boxX, boxY, boxW, boxH);
			// ctx.strokeStyle = "white";
			// ctx.stroke();

			ctx.beginPath();
			ctx.fillStyle = "white";
			ctx.fillRect(0, 0, fullWidth, boxY);
			ctx.stroke();

			ctx.beginPath();
			ctx.fillStyle = "white";
			ctx.fillRect(0, boxY - 2, margin, boxH + 4);
			ctx.stroke();

			ctx.beginPath();
			ctx.fillStyle = "white";
			ctx.fillRect(boxW + margin, boxY - 2, margin, boxH + 4);
			ctx.stroke();

			ctx.beginPath();
			ctx.fillStyle = "white";
			ctx.fillRect(0, boxY + boxH, fullWidth, fullHeight - boxY - boxH);
			ctx.stroke();

			if (!crop.current) {
				crop.current = true;
				requestAnimationFrame(drawCrop);
			}
			requestAnimationFrame(drawFrame);
		} else requestAnimationFrame(drawFrame);
	}, [crop, video/*, zoom*/]);

	const drawCrop = useCallback(() => {
		if (crop.current && video && video.current && video.current.readyState === video.current.HAVE_ENOUGH_DATA) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			const img = canvasRef.current;
			const sx = 0 + margin;
			const sy = fullHeight / 2 - fullWidth / 2;
			const sw = fullWidth - margin * 2;
			const sh = sw;
			canvas.width = sw;
			canvas.height = sh;
			const dx = 0;
			const dy = 0;
			const dw = canvas.width;
			const dh = canvas.height;

			ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
			const imageData = ctx.getImageData(0, 0, dw, dh);
			let code = jsQR(imageData.data, imageData.width, imageData.height);
			const laundroRegex = /\/doPayment/gi;
			const loyaltyRegex = /\/welcome/gi;

			if (code) {
				const urlArray = code.data.split("?");
				let { machineNo, operatorCode, outletCode, opId } = queryString.parse(urlArray[1]);

				// washstudio hardcode outlet
				if (outletCode === "84482b2d573d9f5a397db6a6f2064566a8b3bf1a6ac9e220" && operatorCode === "87597e2b1e71d05a706dbdadb41a4374acf8ee107e88b323") {
					outletCode = "811c267b4525c7537c35a4a2e2121f29b7efbc5365c8e672f99a30ab72109b7e"
					operatorCode = "c31d7b7a132b96573031e5f9b8111426efb2be59628fec20"
				}

				// cleanpro hardcode outlet
				if (STORETYPE === "cleanpro" && machineNo?.split("-")[0] === "azjo") {
					machineNo = machineNo.replace("azjo", "tmwk")
				}

				let laundroRegexTest = laundroRegex.test(code.data)
				let loyaltyRegexTest = loyaltyRegex.test(code.data)

				if (laundroRegexTest && machineNo && operatorCode && outletCode) {
					axios
						.post("/api/user/getMachine", { machineNo, operatorCode, outletCode, operatorId, migrate_id: user.migrate_id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							if (res.data && res.data.data) {
								const response = decrypt(res.data.data);
								scanned(response);
								saveCredentials({ machineNo, operatorCode, outletCode });
								if (user.migrate_id) checkToken()
								if (response.outletStatus !== "online") {
									mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info").then(() => {
										canvas.remove();
										requestAnimationFrame(drawCrop);
									})
								} else if (STORETYPE !== "cuci" && response.block) {
									mySwal.fire(t("Machine is Out of Service"), "", "info").then(() => {
										canvas.remove();
										requestAnimationFrame(drawCrop);
									})
								} else {
									history.push("/user/payment");
								}
							}
						})
						.catch(err => {
							mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => {
								canvas.remove();
								requestAnimationFrame(drawCrop);
							})
						});
				} else if (loyaltyRegexTest && machineNo && operatorCode && outletCode && opId) {
					axios
						.post("/api/user/getMachine", { machineNo, operatorCode: opId, outletCode, operatorId: operatorId || operatorCode, migrate_id: user.migrate_id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							if (res.data && res.data.data) {
								const response = decrypt(res.data.data);
								scanned(response);
								saveCredentials({ machineNo, operatorCode: opId, outletCode });
								if (user.migrate_id) checkToken()
								if (response.outletStatus !== "online") {
									mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info").then(() => {
										canvas.remove();
										requestAnimationFrame(drawCrop);
									})
								} else if (STORETYPE !== "cuci" && response.block) {
									mySwal.fire(t("Machine is Out of Service"), "", "info").then(() => {
										canvas.remove();
										requestAnimationFrame(drawCrop);
									})
								} else {
									history.push("/user/payment");
								}
							}
						})
						.catch(err => {
							mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => {
								canvas.remove();
								requestAnimationFrame(drawCrop);
							})
						});
				} else if (loyaltyRegexTest && operatorCode && outletCode && opId) {
					axios
						.post("/api/user/getDngPricing", { opId, outletCode, operatorId: operatorId || operatorCode, migrate_id: user.migrate_id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							if (res.data && res.data.data) {
								const response = decrypt(res.data.data);
								scanned(response);
								saveCredentials({ operatorCode: opId, outletCode });
								if (user.migrate_id) checkToken()
								history.push("/user/dng/payment");
							}
						})
						.catch(err => {
							mySwal.fire(t("Error"), t(err.response.data.error), "error").then(() => {
								canvas.remove();
								requestAnimationFrame(drawCrop);
							})
						});
				} else {
					mySwal.fire(t("Error"), t("This is not a valid QRCode"), "error").then(() => {
						canvas.remove();
						requestAnimationFrame(drawCrop);
					})
				}
			} else {
				canvas.remove();
				requestAnimationFrame(drawCrop);
			}
		} else requestAnimationFrame(drawCrop);
	}, [crop, video/*, zoom*/]);

	const handleFlash = useCallback(
		val => {
			if (torch && flash !== val && video && video.current) {
				const track = video.current.srcObject.getVideoTracks()[0];
				track.applyConstraints({
					advanced: [
						{
							torch: val
						}
					]
				});
				setFlash(val);
			}
		},
		[torch, flash, video]
	);

	// get constraints
	useEffect(() => {
		(async () => {
			// full hd
			let width = 1920;
			let height = 1080;
			// quad hd
			// let width = 2560;
			// let height = 1440;
			// 4k
			// let width = 3840;
			// let height = 2160;
			if (fullHeight > fullWidth) {
				// portrait
				width = 1080;
				height = 1920;
			}
			try {
				let videoConstraints = {};
				const constraints = navigator.mediaDevices.getSupportedConstraints();
				if (constraints.width) videoConstraints.width = { ideal: width };
				if (constraints.height) videoConstraints.height = { ideal: height };
				if (constraints.frameRate) videoConstraints.frameRate = { ideal: 60 };
				if (constraints.facingMode) videoConstraints.facingMode = "environment";
				if (constraints.zoom) videoConstraints.zoom = true;
				if (constraints.torch) videoConstraints.torch = true;
				const setThis = {
					video: videoConstraints,
					audio: false
				};
				setConstraint(setThis);
			} catch (error) {
				mySwal.fire(t("Error"), JSON.stringify(error.message), "error")
			}
		})();
	}, []);

	// get available devices
	useEffect(() => {
		(async () => {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices();
				const videoDevices = devices.filter(device => device.kind === "videoinput");
				const setThis = videoDevices.map((device, index) => ({
					deviceId: device.deviceId,
					groupId: device.groupId,
					kind: device.kind,
					label: device.label ? device.label : `Camera ${index + 1}`
				}));
				setVideoDevices(setThis);
			} catch (error) {
				mySwal.fire(t("Error"), error.message, "error")
			}
		})();
	}, []);

	const stopCamera = () => {
		crop.current = false;
		cancelAnimationFrame(drawFrame);
		cancelAnimationFrame(drawCrop);
		if (video.current) {
			if (video.current.srcObject && video.current.srcObject.getTracks().length)
				video.current.srcObject.getTracks().forEach(x => x.stop());
			if (video.current.srcObject) video.current.srcObject = null;
			if (video.current.src) video.current.src = null;
			video.current.pause();
			video.current.remove();
			video.current = null;
		}
	}

	// start camera
	useEffect(() => {
		if (constraint) {
			(async () => {
				try {
					stopCamera()

					const myVideo = document.createElement("video");
					myVideo.setAttribute("playsinline", true);
					myVideo.setAttribute("muted", true);
					myVideo.setAttribute("autoplay", true);

					const stream = await navigator.mediaDevices.getUserMedia(constraint);
					setPlaying(true)
					myVideo.srcObject = stream;
					myVideo.play();

					const track = myVideo.srcObject.getVideoTracks()[0];
					const settings = track.getSettings();

					// if ("zoom" in settings) {
					// 	if (track.getCapabilities && track.getCapabilities()) {
					// 		const capabilities = track.getCapabilities();
					// 		track.applyConstraints({
					// 			advanced: [
					// 				{
					// 					zoom: capabilities.zoom.max / 2 - 2
					// 				}
					// 			]
					// 		});
					// 	}
					// }

					if ("torch" in settings) {
						setTorch(true)
						setFlash(true)
					} else {
						setTorch(false)
						setFlash(false)
					}

					myVideo.onplaying = () => {
						video.current = myVideo;
						requestAnimationFrame(drawFrame);
						myVideo.remove();
					};
				} catch (error) {
					mySwal.fire(t("Error"), error.message, "error")
				}
			})();
		}

		// unmount
		return () => stopCamera()
	}, [constraint, video/*, zoom*/]);

	const switchCamera = useCallback(
		deviceId => {
			(async () => {
				try {
					const newConstraint = {
						...constraint,
						video: {
							...constraint.video,
							deviceId: { exact: deviceId }
						}
					};

					stopCamera()

					const myVideo = document.createElement("video");
					myVideo.setAttribute("playsinline", true);
					myVideo.setAttribute("muted", true);
					myVideo.setAttribute("autoplay", true);

					const stream = await navigator.mediaDevices.getUserMedia(newConstraint);
					myVideo.srcObject = stream;
					myVideo.play();

					const track = myVideo.srcObject.getVideoTracks()[0];
					const settings = track.getSettings();

					// if ("zoom" in settings) {
					// 	if (track.getCapabilities && track.getCapabilities()) {
					// 		const capabilities = track.getCapabilities();
					// 		track.applyConstraints({
					// 			advanced: [
					// 				{
					// 					zoom: capabilities.zoom.max / 2 - 2
					// 				}
					// 			]
					// 		});
					// 	}
					// }

					if ("torch" in settings) {
						setTorch(true)
						setFlash(true)
					} else {
						setTorch(false)
						setFlash(false)
					}

					myVideo.onplaying = () => {
						video.current = myVideo;
						canvasRef.current.width = fullWidth;
						canvasRef.current.height = fullHeight;
						requestAnimationFrame(drawFrame);
						myVideo.remove();
						setSwitchCameraModal(false);
					};
				} catch (error) {
					mySwal.fire(t("Error"), error.message, "error")
				}
			})();
		},
		[constraint, video]
	);

	return (
		<div id="camera-page" style={{ position: "relative", padding: "0 0 100px 0", minHeight: "100vh" }}>
			<div style={{ margin: 0, padding: 0, overflow: "hidden", position: "relative", height: "100%" }}>
				<canvas ref={canvasRef} width={fullWidth} height={fullHeight}></canvas>

				<div id="user-header" style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
					<h5>
						<strong>{t("Camera")}</strong>
					</h5>
				</div>

				{
					!playing ? (
						<div style={{ position: "absolute", top: "14vh", left: 0, width: "100%", textAlign: "center" }}>
							<p style={{ margin: 0, padding: 0 }}>{t("Please allow this webapp to access your camera")}</p>
						</div>
					) : (
						<>
							<div style={{ position: "absolute", top: `${(fullHeight / 2 - fullWidth / 2) + (fullWidth - margin * 2) + 10}px`, left: 0, width: "100%", textAlign: "center" }}>
								<p style={{ margin: 0, padding: 0 }}>{t("Scan the QR code on the machine.")}</p>
							</div>
							<div style={{ position: "absolute", top: `${(fullHeight / 2 - fullWidth / 2) + (fullWidth - margin * 2) + 40}px`, left: 0, width: "100%", textAlign: "center" }}>
								<p style={{ margin: 0, padding: 0 }}>
									{
										STORETYPE === "cleanpro" ? <small>* {t("Switch your camera if the display is not clear")}</small> : <small>* {t("switch camera if your camera is not clear")}</small>
									}
								</p>
							</div>
							<div style={{ position: "absolute", top: `${(fullHeight / 2 - fullWidth / 2) + (fullWidth - margin * 2) + 70}px`, left: 0, width: "100%", textAlign: "center" }} className="d-flex justify-content-center">
								{
									torch ? (
										<div>
											{
												STORETYPE === "cleanpro" ? (
													<div className="pe-4" onClick={() => handleFlash(!flash)}>
														<img src={cleanproLightIcon} id="cleanpro-torchlight" alt="light-icon" style={{ width: "60px" }} />
													</div>
												) : (
													<button className="btn btn-primary me-3" id="qr-torchlight" onClick={() => handleFlash(!flash)}>
														{flash ? t("Off torch light") : t("On torch light")}
													</button>
												)
											}
										</div>
									) : null
								}
								{
									STORETYPE === "cleanpro" ? (
										<div className="pe-4" onClick={() => setSwitchCameraModal(true)}>
											<img src={cleanproCameraIcon} alt="camera-icon" id="cleanpro-switch-camera" style={{ width: "60px" }} />
										</div>
									) : (
										<button className="btn btn-primary me-3" id="switch-camera" onClick={() => setSwitchCameraModal(true)}>
											{t("Switch Camera")}
										</button>
									)
								}
								{/* <button className="btn btn-primary me-3" id="switch-camera" onClick={() => setZoom(zoom + 10)}>
									{t("Zoom In")}
								</button>
								<button className="btn btn-primary me-3" id="switch-camera" onClick={() => setZoom(zoom - 10)}>
									{t("Zoom Out")}
								</button> */}
							</div>
						</>
					)
				}
			</div>
			<Modal
				show={switchCameraModal}
				onHide={() => setSwitchCameraModal(false)}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("Select Camera")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={theme}>
						<div className="form-group">
							<select className="browser-default form-select" id="camera" name="camera" value={selectedDeviceId} onChange={e => setSelectedDeviceId(e.target.value)} required>
								<option value="" disabled>{t("Select Camera")}</option>
								{
									videoDevices.map((device, id) => {
										return (
											<option value={device.deviceId} key={id}>{device.label}</option>
										)
									})
								}
							</select>
						</div>
						<div className="d-flex justify-content-center">
							<div className="me-2">
								<button id="confirm-switch-camera" className="btn btn-primary" disabled={!selectedDeviceId} onClick={() => switchCamera(selectedDeviceId)}>
									{t("Switch")}
								</button>
							</div>
							<div>
								<button id="cancel-switch-camera" className="btn btn-secondary" onClick={() => setSwitchCameraModal(false)}>
									{t("Cancel")}
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		token: state.user.token,
		operatorId: state.user.operatorId,
		user: state.user.user,
		theme: state.theme.theme
	};
};

const mapDispatchToProps = dispatch => {
	return {
		scanned: data => dispatch(scannedCode(data)),
		saveCredentials: data => dispatch(saveCreds(data)),
		checkToken: () => dispatch(checkToken()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Camera);